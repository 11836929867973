import { type MetaFunction } from '@remix-run/node';
import { PartnerCarousel } from '#app/components/PartnerCarousel';
import memorialImg from '#app/images/mount-hope-02@0.5x.png';
export const meta: MetaFunction = () => [{ title: 'RHSR | Home' }];

export default function Index() {
  return (
    <div className="flex flex-col justify-between">
      <div
        className="ml-[-2.5rem] mt-[-2.5rem] flex w-[calc(100%+5rem)] justify-between
          bg-primary-500 px-8 text-secondary-50"
      >
        <div className="mb-12 mt-48 flex w-1/2 flex-col justify-end p-8">
          <h1 className=" h-fit max-w-4xl font-serif text-[98px] leading-[0.9em] tracking-tight">
            Rochester
            <br />
            Holocaust
            <br />
            Survivors
            <br /> Registry
          </h1>
        </div>
        <div className="flex w-1/2 flex-col justify-center">
          <h2 className="mx-auto mt-8 text-2xl ">
            a comprehensive database of Rochester Holocaust survivors
          </h2>
        </div>
      </div>
      <div className="container flex gap-4 py-8 text-2xl sm:flex-col-reverse md:flex-row">
        <div className="sm:w-full md:w-1/2 lg:w-2/3">
          <p className="mb-4">
            In July of 2023, The Holocaust Committee of The Friends of Mount
            Hope Cemetery received an Ignite Impact Grant from the Jewish
            Federation of Greater Rochester to create an online, comprehensive,
            community-wide database of all Holocaust survivors that settled in
            the Rochester, NY region. This was not an original idea, but rather
            built on the work that had been started more than 35 years before by
            the Jewish Community Center of Rochester in creating a Holocaust
            Memorial Garden, including a wall with the engraved names of
            Rochester Holocaust survivors. Originally the JCC intended to
            digitize and keep all Holocaust media on a computer overlooking the
            garden. By the late 1990s the internet became the favored tool for
            doing research, so the information from the JCC computer was
            transferred to a website that could be accessed by all, hosted by
            the Center for Holocaust Awareness and Information (CHAI), a
            division of the Jewish Federation of Greater Rochester.
          </p>
          <p>
            Over time, other lists of Rochester Holocaust survivors were
            created, such as the Photo Essay Series (started in 1996) at Monroe
            Community College’s Holocaust Genocide and Human Rights Project
            (HGHRP) and the Mount Hope Holocaust Archive (started in 2021) on
            The Friends of Mount Hope Cemetery website (FOMH). Each listing told
            the stories of some Rochester Holocaust survivors, with much overlap
            among them. No one database told the complete story. And so, with
            the support of the Director of CHAI and the Director of HGHRP, the
            Holocaust Committee of FOMH undertook combining, streamlining, and
            making more complete one community-wide database. This is the
            starting point for this project. More names have been added to those
            already known through both research and interviews with survivor
            families. This new database still requires much research to tell the
            stories of all the survivors, but basic information is available for
            each survivor, including date of birth, location of birth, date of
            death (if appropriate) and burial location.
          </p>
        </div>
        <div className="sm:w-full md:w-1/2 lg:w-1/3">
          <img src={memorialImg} alt="Mount Hope Cemetery" width={600} />
        </div>
      </div>
      <div
        className="mb-[-2.5rem] ml-[-2.5rem] w-[calc(100%+5rem)] bg-primary-500 p-10
          text-secondary-50"
      >
        <div className="container">
          <h2 className="mb-8 font-serif text-5xl">Our Partners</h2>
          <PartnerCarousel />
        </div>
      </div>
    </div>
  );
}
