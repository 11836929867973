import { type FC } from 'react';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from './ui/carousel';

export interface PartnerCarouselProps {}

const partners = [
  {
    imgSrc: '/img/partners/fomh.png',
    alt: 'Friends of Mount Hope',
    href: 'https://fomh.org',
  },
  {
    imgSrc: '/img/partners/jewishFederation.webp',
    alt: 'Jewish Federation',
    href: 'https://www.jewishrochester.org/',
  },
  {
    imgSrc: '/img/partners/universityOfRochester.webp',
    alt: 'University of Rochester',
    href: 'https://www.rochester.edu/',
  },
  {
    imgSrc: '/img/partners/mcc.webp',
    alt: 'Monroe Community College',
    href: 'https://www.monroecc.edu/',
  },
  {
    imgSrc: '/img/partners/jcc.webp',
    alt: 'Jewish Community Center',
    href: 'https://jccrochester.org/',
  },
  {
    imgSrc: '/img/partners/jfs.webp',
    alt: 'Jewish Family Service',
    href: 'https://jfsrochester.org/',
  },
];

export const PartnerCarousel: FC<PartnerCarouselProps> = () => {
  return (
    <Carousel
      className="w-full"
      opts={{
        loop: true,
        // active: true,
      }}
    >
      <CarouselContent>
        {partners.map(({ imgSrc, alt, href }) => (
          <CarouselItem
            key={imgSrc}
            className="flex basis-1/4 items-center justify-center"
          >
            <a
              key={imgSrc}
              href={href}
              target="_blank"
              rel="noreferrer"
              className="bg-secondary-100 flex h-80 w-80 items-center justify-center"
            >
              <img src={imgSrc} alt={alt} className="w-9/12" />
            </a>
          </CarouselItem>
        ))}
      </CarouselContent>
      <CarouselPrevious />
      <CarouselNext />
    </Carousel>
  );
};
